import React, { useEffect } from 'react';
import {
  Link, useNavigate
} from "react-router-dom";

import AuthenticationService from '../services/authenticationservice.js';

const importScript = src => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [src])
}

const SiteHeaderComponent = (props) => {

	const authenticationService = new AuthenticationService();
	const navigate = useNavigate();

	//importScript("/assets/SiteTheme/js/custom.js");

	const onClickNav = async (redirectPath) => {
		window.scrollTo(0, 0);
		//console.log("V", redirectPath);

		var nav_height = jQuery('header')[0]["style"].height;

		if(nav_height != "auto")
		{
			jQuery('header').removeClass('menu-open');
			jQuery('header').css('height','auto');
		}
		navigate(redirectPath, { replace: true });
		return false;
	}

	return (
        <header className="header-light transparent has-topbar">
            <div id="topbar">
                <div className="container">
                    <div className="topbar-left xs-hide">
                        <span className="topbar-widget">
							<i className="fa fa-envelope"></i>
							<img src="/assets/SiteTheme/images/img_sales_addr.png" />
                        </span>
                    </div>
                
                    <div className="topbar-right">
                        <div className="topbar-widget">

						</div>
                        <div className="topbar-widget">

						</div>
                        <div className="topbar-widget">
							<strong>Skype</strong>:&nbsp; <img src="/assets/SiteTheme/images/img_sales_addr.png" />
						</div>
                    </div>  
                    <div className="clearfix"></div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="de-flex sm-pt10">
                            <div className="de-flex-col">
                                <div className="de-flex-col">

                                    <div id="logo">
										<Link className="" to={`/`}>
											<img className="logo-main" src="/assets/SiteTheme/images/logo.png" alt="" />
											<img className="logo-mobile" src="/assets/SiteTheme/images/logo-mobile.png" alt="" />
										</Link>
                                    </div>

                                </div>
                            </div>
                            <div className="de-flex-col header-col-mid">
                                <ul id="mainmenu">
                                    <li><Link className="menu-item" to={`/`}>Home</Link> </li>

									<li><Link to='/directadmin-shared-hosting' className="menu-item" onClick={() => onClickNav('/directadmin-shared-hosting')}>Shared Hosting</Link> </li>

									<li><Link to='/directadmin-reseller-hosting' className="menu-item" onClick={() => onClickNav('/directadmin-reseller-hosting')}>Reseller Hosting</Link> </li>

									<li><Link to='/wordpress-hosting' className="menu-item" onClick={() => onClickNav('/wordpress-hosting')}>WordPress Hosting</Link> </li>

                                    <li><a className="menu-item" href="#">Contact Us</a>
                                        <ul>
											<li><a className="menu-item" href="https://clients.hostpallet.com/submitticket.php">Open Support Ticket</a> </li>
											<li><a href="#" className="menu-item" onClick={() => onClickNav('/tos')}>Terms Of Service</a> </li>
											<li><a href="#" className="menu-item" onClick={() => onClickNav('/aup')}>Acceptable Use Policy</a> </li>
											<li><a href="#" className="menu-item" onClick={() => onClickNav('/faq')}>FAQ</a> </li>
                                            <li><a href="#" className="menu-item" onClick={() => onClickNav('/contact')}>Contact Us</a> </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div className="de-flex-col">
                                <div className="menu_side_area">
									<Link className="btn-main" to={`/contact`}>Have questions?</Link>
                                    <span id="menu-btn"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
	);

};

export default SiteHeaderComponent;
